<template>
	<div>
		<div class="subtitle rbc">
			<span>添加单号</span>
			<el-button type="primary" @click="add">+ 添加</el-button>
		</div>
		<div class="number">
			<el-table max-height="350" :data="tableData" stripe border style="width: 100%"
				:header-cell-style="{background:'#EBF6FC',color:'#999999'}">
				<!-- <el-table-column type="selection" width="45" /> -->
				<el-table-column type="index" width="45" />
				<el-table-column prop="username" label="姓名" width="100" />
				<el-table-column prop="phone" label="手机号" width="150" />
				<el-table-column prop="address" label="地址" />
				<el-table-column fixed="right" label="操作" width="120">
					<template slot-scope="scope">
						<el-button @click="editRow(scope.row)" type="text" size="small">
							编辑</el-button>
						<el-popconfirm @confirm="customerDel(scope.row.id)" title="确定删除吗？">
							<el-button style="margin-left: 10px;" slot="reference" type="text" size="small">
								<span style="color: #ff5500">删除</span>
							</el-button>
						</el-popconfirm>
					</template>
				</el-table-column>

			</el-table>
		</div>
		<el-dialog title="添加客户" :visible.sync="dialogFormVisible" append-to-body>
			<el-form :model="form" status-icon class="demo-form-inline" :rules="rules" ref="ruleForm">
				<el-form-item label="姓名" prop="username">
					<el-input clearable v-model="form.username" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item label="手机" prop="phone">
					<el-input clearable v-model="form.phone" placeholder="请输入手机"></el-input>
				</el-form-item>
				<el-form-item label="地址" prop="address">
					<el-input clearable placeholder="请输入地址" :autosize="{ minRows: 2, maxRows: 4}" type="textarea"
						v-model="form.address"></el-input>
				</el-form-item>
				<el-form-item class="df jcfe">
					<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
					<el-button @click="resetForm('ruleForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				status: null,
				dialogFormVisible: false,
				tableData: [],
				form: {
					username: '',
					phone: '',
					address: '',
					id: ''
				},
				rules: {
					username: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入地址',
						trigger: 'blur'
					}],
				},
			}
		},
		mounted() {
			this.customerList()
		},
		methods: {
			add() {
				this.status = '添加'
				this.dialogFormVisible = true
				// this.form.forEach(v=>v='')
				Object.keys(this.form).forEach(v => this.form[v] = '')
				// setTimeout(()=>this.resetForm('ruleForm'),100)

			},
			editRow(row) {
				this.status = '编辑'
				this.dialogFormVisible = true;
				this.form = row
			},
			async customerDel(id) {
				await this.$api.customerDel(id)
				this.$message.success('删除成功')
				this.customerList()
			},
			async customerUpdate() {
				await this.$api.customerUpdate(this.form)
				this.$message.success('修改成功')
				this.dialogFormVisible = false;
				this.customerList()
			},
			async customerList() {
				let {
					list
				} = await this.$api.customerList()
				this.tableData = list
			},
			async customerCreate() {
				await this.$api.customerCreate(this.form)
				this.$message.success('添加成功')
				this.dialogFormVisible = false;
				this.customerList()
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.status == '添加' ? this.customerCreate() : this.customerUpdate()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style lang="scss" scoped>
	.subtitle {
		width: 100%;
		height: 50px;
		margin-bottom: 20px;
		padding: 0 20px;

		span:nth-child(1) {
			font-size: 1rem;
			font-weight: bold;
			margin-left: 13px;
			position: relative;
			font-family: PingFang SC;

			&::after {
				content: "";
				display: block;
				width: 3px;
				height: 19px;
				background: #3F7CF8;
				position: absolute;
				top: 2px;
				left: -13px;
			}
		}
	}
</style>
